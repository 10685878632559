import isEmpty from "is-empty";
import { useState, useContext, createContext, useEffect } from "react";

import { LOGO_URL } from "../../utils/Constants";

import { useSelector, useDispatch } from "react-redux";
import CustomModal from "../assest/UI/Modal";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";

import { useNavigate } from "react-router-dom";

import { Dologin } from "../../services/Service";
import Loader from "../assest/UI/Loader";

function Login({ navigation }) {
  let navigate = useNavigate();
  let globalstoredata = useSelector((state) => state.AuthReducer.isLogin);
  let balanceDetails = useSelector(
    (state) => state.WalletBalanceReducer.balance
  );

  console.log("get user selector data" + globalstoredata);

  const [errorMessage, setErrormessage] = useState("");
  const [userDetails, setUserDetails] = useState({ email: "", password: "" });
  const [isLoging, setIsLoging] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [errors,setErrors]=useState({ email: "", password: "" })

  const handleChange = (e) => {
    setUserDetails((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  //login valid check
  const loginValid = () => {
    let valid = true;
    let newErrors = {};
  
    // Validate Mobile Number
    if (userDetails.email === '') {
      newErrors.email = 'Mobile number is required';
      valid = false;
    } else if (!validateMobileNumber(userDetails.email)) {
      newErrors.email = 'Invalid mobile number';
      valid = false;
    }
    // Validate Password
    if (userDetails.password === '') {
      newErrors.password = 'Password is required';
      valid = false;
    }
    setErrors(newErrors);
    return valid;
  };
  
const validateMobileNumber = number => {
  const phoneRegex = /^[0-9]{10}$/;
  return phoneRegex.test(number);
};

  const loginSubmitHandler = async (e) => {
    e.preventDefault();

    if(loginValid()){
      setIsLoging(true);
      setIsLoading(true);
      console.log("user details==>", userDetails);
      const res = await Dologin(userDetails);
  
      if (res.response_code === 200) {
        //onSubmitUserHandler(res?.response);
        // console.log(userDetails);
        localStorage.setItem("token", res.data.Sessionkey);
        localStorage.setItem("userDta", JSON.stringify(res.data));
  
        window.location.reload(navigate("/dashboard"));
        toast(res.message);
        setIsLoging(false);
        setIsLoading(false);
  
      } else if (res.response_code === 500) {
        toast.error(res.message);
        setIsLoging(false);
        setIsLoading(false);
        if (!isEmpty(res.message)) {
          setErrormessage(res.message);
        }
      }
    }else{
      return false;
    }
  };

  return (
    <>
      <section className="vh-100">
        <div className="container py-5 h-100">
          <div className="row h-100">
            {/* {/ Image Section - Left Side /} */}
            <div className="col-md-6 d-flex justify-content-center align-items-center">
              <img
                src="img/booklogin.png"
                className="img-fluid"
                alt="Phone image"
                style={{ maxWidth: "60%", height: "500" }} // Ensuring the image does not exceed the container width
              />
            </div>

            {/* {/ Form Section - Right Side /} */}
            <div className="col-md-6 d-flex align-items-center justify-content-center">
              <form onSubmit={loginSubmitHandler} className="w-75 p-3">
                <div className="mb-4">
                  <h2 className="fw-bold fst-italic text-center">
                    Welcome to Study Link
                  </h2>
                </div>
                {/* {/ Email input /}
                 */}
                <div data-mdb-input-init className="form-outline mb-4">
                  <input
                    type="number"
                    id="form1Example13"
                    placeholder="Moblie Number"
                    className="form-control form-control-md"
                    name="email"
                    onChange={handleChange}
                  />
                 {errors.email && (
                      <small className="text-danger">{errors.email}</small>
                    )}
                </div>

                <div data-mdb-input-init className="form-outline mb-4">
                  <input
                    type="password"
                    id="form1Example23"
                    placeholder="Password"
                    className="form-control form-control-md"
                    name="password"
                    onChange={handleChange}
                  />
                  {errors.password && (
                      <small className="text-danger">{errors.password}</small>
                    )}
                </div>

                {/* {/ Checkbox and Forgot Password /} */}
                <div className="d-flex justify-content-between align-items-center mb-4">
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value=""
                      id="form1Example3"
                      defaultChecked
                    />
                    <label className="form-check-label" htmlFor="form1Example3">
                      {" "}
                      Remember me{" "}
                    </label>
                  </div>
                  <a href="/forgotPassword">Forgot password?</a>
                </div>

                {/* {/ Submit Button /} */}
                <button
                  type="submit"
                  data-mdb-button-init
                  data-mdb-ripple-init
                  className="btn btn-primary btn-md btn-block"
                  disabled={isLoging}
                >
                   {isLoading && <Loader />}
                  Sign in
                </button>
               
                {/* {/ Account Creation Link /} */}
                <div className="text-center mt-4">
                  <p className="mb-0">
                    Don't you have an account?{" "}
                    <a
                      href="https://studylinkclassesedu.in/contact-us"
                      className="text-primary fw-bold"
                    >
                      click here
                    </a>
                  </p>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Login;
