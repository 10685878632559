import React from "react";
import Menu from "./Menu";
import {NavLink,Link} from "react-router-dom";


import { LOGO_URL } from "../../../utils/Constants.js";
export default function SideNavigation() {
  return (
    <aside class="main-sidebar sidebar-dark-primary elevation-4">
      <img
        src="img/logo_1.png"
        className="mt-2"
        style={{ width: "60px" }}
        alt="AdminLTE Logo"
        class=""
      />
      <div class="">
        <nav class="mt-2">
          <div class="sidebar-sticky">
            <ul
              class="nav nav-pills nav-sidebar flex-column"
              data-widget="treeview"
            >
              <Menu />
            </ul>
          </div>
        </nav>
      </div>
    </aside>
  );
}
