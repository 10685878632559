
import react,{useEffect} from 'react';
import { useNavigate,Link } from "react-router-dom";
import {useSelector,useDispatch} from "react-redux";
import { FaWallet,FaUserAlt,FaSignOutAlt } from "react-icons/fa";


import {wallet_balance} from "./../../../store/action/DashboardAction";

export default function AdminTopNavigation(props){
  //get balance from main store
  let balanceDetails  =  useSelector((state) => state.WalletBalanceReducer.balance);
  let navigate = useNavigate();
    const logout = () => {
      debugger;
      localStorage.clear();
      navigate("/login");
    }
    //let dispach = useDispatch();

    /* useEffect(()=> {
      dispach({type:"UPDATE_BALANCE",payload:"0222"});
    },[])*/
    //
    const dispatch = useDispatch();
    const getBalance = async() => {
      dispatch(wallet_balance());
    }
  
    useEffect(()=> {
     getBalance();
    },[])
    let userDta =  JSON.parse(localStorage.getItem("userDta"));

    return (
    <div>  
            <nav class="main-header navbar navbar-expand navbar-dark headerfix">
            
            <ul class="navbar-nav">
                <li class="nav-item">
                <a class="nav-link" data-widget="pushmenu" href="#" role="button"><i class="fas fa-bars"></i></a>
                </li>
                {/*<li class="nav-item d-none d-sm-inline-block">
                <a href="index3.html" class="nav-link">Home</a>
                </li>
                <li class="nav-item d-none d-sm-inline-block">
                <a href="#" class="nav-link">Contact</a>
                </li> */}
            </ul>

            
            <ul class="navbar-nav ml-auto">
  
            
            </ul>

            <div class="">
              <div class="navbar-nav">
                
                  <div class="nav-item wallet_balance"> <FaUserAlt /> {`${userDta.user.first_name} ${userDta.user.last_name}    `} </div>
                  &nbsp; &nbsp;<div class="nav-item wallet_balance"> 
                  &nbsp;  <FaSignOutAlt onClick={logout}/>  &nbsp;&nbsp;
                  </div>
              </div>
            </div>

          

       




            </nav>

           
        </div>)
}